#Supporter{
    padding-top: 30px;
    width: 100%;
    height: 30vh;
    background-color: white;
  }
  
  .Supporter-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .Supporter-content img {
    max-width: 20%;
    height: auto; /* 이미지 비율 유지 */
    border-radius: 8px; /* 이미지 모서리를 둥글게 */
  }

