/* 기본 스타일 */
.heroDiv {
  height: 100vh;
  position: relative;
  padding-top: 200px;
}

#mission {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#mission > div {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#mission h1 {
  font-size: 100px;
}

#mission p {
  line-height: 1.5em;
  font-size: 50px;
  margin: 30px 0;
}

#mission a {
  width: 100%;
}
/* #mission Section */
#mission {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#mission > div {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#mission h1 {
  font-size: 100px;
}

#mission p {
  line-height: 1.5em;
  font-size: 50px;
  margin: 30px 0;
}

#mission a {
  width: 100%;
}

/* Responsive Design */
@media (max-width: 1024px) {
  #mission h1 {
    font-size: 80px; /* Adjust font size for tablets */
  }

  #mission p {
    font-size: 40px; /* Adjust font size for tablets */
    margin: 20px 0;  /* Adjust margin for better spacing on tablets */
  }

  #mission > div {
    margin-top: 80px; /* Reduce top margin for tablets */
  }
}

@media (max-width: 768px) {
  #mission h1 {
    font-size: 60px; /* Adjust font size for mobile devices */
  }

  #mission p {
    font-size: 30px; /* Adjust font size for mobile devices */
    margin: 15px 0; /* Adjust margin for better spacing on mobile devices */
  }

  #mission > div {
    margin-top: 60px; /* Reduce top margin for mobile devices */
  }
}

@media (max-width: 480px) {
  #mission h1 {
    margin-top: 50px;
    text-align: center;
    font-size: 40px; /* Adjust font size for very small devices */
  }

  #mission p {
    text-align: center;
    font-size: 20px; /* Adjust font size for very small devices */
    margin: 10px 0; /* Adjust margin for better spacing on very small devices */
  }

  #mission > div {
    text-align: center;
    margin-top: 40px; /* Reduce top margin for very small devices */
  }
}


.items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 700px;
  flex-wrap: wrap; /* 요소들이 줄 바꿈되도록 설정 */
}

.item1, .item2, .item3, .item4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 10px 8px rgba(0, 0, 0, 0.3);
  margin: 10px; /* 아이템 사이 여백 */
}

.item1 > div, .item2 > div, .item3 > div, .item4 > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* 상단 정렬 */
  align-items: center;
  text-align: center; /* 텍스트 가운데 정렬 */
  color: white;
  width: 350px;
  height: 500px;
  background-size: cover;
  background-position: center;
}

.item1 h3, .item2 h3, .item3 h3, .item4 h3 {
  font-weight: 700; /* 폰트 두께 설정 */
  margin: 15px; /* 기본 마진 */
  position: relative; /* 위치 조정을 위한 relative 설정 */
  padding: 10px; /* 상자 안의 여백 */
  background: rgba(0, 0, 0, 0.5); /* 배경 색상과 투명도 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* 상자 그림자 효과 */
}

/* 반응형 스타일 */
@media (max-width: 1480px) {
  .item1, .item2, .item3, .item4 {
    width: 300px;
    height: 450px;
  }

  .item1 > div, .item2 > div, .item3 > div, .item4 > div {
    width: 300px;
    height: 450px;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 1280px) {
  .item1, .item2, .item3, .item4 {
    width: 275px;
    height: 450px;
  }

  .item1 > div, .item2 > div, .item3 > div, .item4 > div {
    width: 275px;
    height: 450px;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 1180px) {
  .item1, .item2, .item3, .item4 {
    width: 250px;
    height: 300px;
  }

  .item1 > div, .item2 > div, .item3 > div, .item4 > div {
    width: 250px;
    height: 300px;
    background-size: cover;
    background-position: center;
  }
}
@media (max-width: 180px) {
  .item1, .item2, .item3, .item4 {
    width: 200px;
    height: 220px;
  }

  .item1 > div, .item2 > div, .item3 > div, .item4 > div {
    width: 200px;
    height: 220px;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 980px) {
  .item1, .item2, .item3, .item4 {
    width: 250px;
    height: 220px;
  }

  .item1 > div, .item2 > div, .item3 > div, .item4 > div {
    width: 250px;
    height: 220px;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 768px) {
  .items {
    flex-direction: column;
    height: auto; /* 자동 높이 조정 */
  }

  .item1, .item2, .item3, .item4 {
    width: 250px;
    height: 220px; /* 자동 높이 조정 */
  }

  .item1 > div, .item2 > div, .item3 > div, .item4 > div {
    width: 250px;
    height: 220px; /* 자동 높이 조정 */
    background-size: cover;
    background-position: center;
  }
  .item1 h3, .item2 h3, .item3 h3, .item4 h3 {
    font-size: 15px; /* 더 작은 화면을 위한 폰트 크기 조정 */
    padding: 5px; /* 패딩 조정 */
  }
}

@media (max-width: 576px) {
  .item1, .item2, .item3, .item4 {
    width: 200px;
    height: 200px; /* 자동 높이 조정 */
  }

  .item1 > div, .item2 > div, .item3 > div, .item4 > div {
    width: 200px;
    height: 200px; /* 자동 높이 조정 */
    background-size: cover;
    background-position: center;
  }

  .item1 h3, .item2 h3, .item3 h3, .item4 h3 {
    font-size: 5px; /* 더 작은 화면을 위한 폰트 크기 조정 */
    padding: 5px; /* 패딩 조정 */
  }
}

@media (max-width: 400px) {
  .item1, .item2, .item3, .item4 {
    width: 200px;
    height: 200px; /* 자동 높이 조정 */
  }

  .item1 > div, .item2 > div, .item3 > div, .item4 > div {
    width: 200px;
    height: 200px; /* 자동 높이 조정 */
    background-size: cover;
    background-position: center;
  }

  .item1 h3, .item2 h3, .item3 h3, .item4 h3 {
    font-size: 5px; /* 더 작은 화면을 위한 폰트 크기 조정 */
    padding: 6px; /* 패딩 조정 */
  }
}

.item1 > div{
  background-image: url('../../components/images/img1.avif');
}
.item2 > div{
  background-image: url('../../components/images/img2.avif');
}
.item3 > div{
  background-image: url('../../components/images/img3.jpg');
}
.item4 > div {
  background-image: url('../../components/images/img4.avif');
}

