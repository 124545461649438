/* 기본 스타일 */
main {
    display: flex;
    height: 100vh;
    width: 375%;
    background-color: #000;
    overflow-x: hidden;
    margin: 0; /* 상하 마진 제거 */
    padding: 0; /* 상하 패딩 제거 */
  }
  
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; /* 세로로 배치 */
    justify-content: center; /* 세로 가운데 정렬 */
    align-items: center; /* 가로 가운데 정렬 */
    font-size: 7rem; /* 글자 크기 조정 */
    color: #fff;
    text-align: center; /* 텍스트 가운데 정렬 */
    padding: 20px; /* 여백 추가 (선택 사항) */
    background-size: cover;
    background-position: center;
    font-weight: bold;
  }
  
  .container h3, .container h5 {
    margin: 30px 0; /* 헤더 사이의 여백 조정 */
  }
  
  #container1 {
    background-image: url('../images/그림2.png'); /* 배경 이미지 A */
    background-size: cover; /* 이미지가 요소를 완전히 덮도록 조절 */
    background-position: center; /* 이미지가 중앙에 위치하도록 조절 */
  }
  
  #container2 {
    background-image: url('../images/그림3.png'); /* 배경 이미지 B */
    background-size: cover; /* 이미지가 요소를 완전히 덮도록 조절 */
    background-position: center; /* 이미지가 중앙에 위치하도록 조절 */
  }
  
  #container3 {
    background-image: url('../images/그림4.png'); /* 배경 이미지 C */
    background-size: cover; /* 이미지가 요소를 완전히 덮도록 조절 */
    background-position: center; /* 이미지가 중앙에 위치하도록 조절 */
  }
  
  #title-container {
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.7)), url(https://images.unsplash.com/photo-1514064019862-23e2a332a6a6?q=80&w=1314&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* 배경 이미지 고정 */
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  
  #title-container, #container4 h1 {
    font-size: 4rem;
  }
  
  #title-container .arrow {
    font-size: 5rem;
  }
  
  #title-container .slide-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%; /* 높이 조정, 필요한 경우 */
  }
  
  #title-container .text {
    text-align: center;
    font-size: 5rem;
    width: 100%;
    color: rgb(182, 182, 182, 0.2);
    background: linear-gradient(to right, #b6b6b6, white) no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    background-size: 0%;
    transition: background-size cubic-bezier(.1, .5, .5, 1) 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  #title-container span {
    position: absolute;
    font-size: 3rem;
    width: 100%;
    height: 100%;
    background-color: #4246ce;
    color: #0D0D0D;
    clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    transform-origin: center;
    transition: all cubic-bezier(.1, .5, .5, 1) 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  #title-container .text:hover > span {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  
  #title-container .a {
    text-decoration: none;
    color: inherit;
  }
  
  /* Media Queries for Responsive Design */
  
  /* For tablets and smaller screens */
  @media (max-width: 1024px) {

    .container {
      font-size: 5rem;
      padding: 10px;
    }
  
    #title-container {
      height: 80vh;
    }
  
    #title-container .text {
      font-size: 3rem;
    }
  
    #title-container span {
      font-size: 2rem;
    }
  
    .container h3, .container h5 {
      margin: 20px 0;
    }
  }
  
  /* For mobile devices */
  @media (max-width: 768px) {
    .container {
      font-size: 4rem;
      padding: 5px;
    }
  
    #title-container {
      height: 70vh;
    }
  
    #title-container .text {
      font-size: 2rem;
    }
  
    #title-container span {
      font-size: 1.5rem;
    }
  
    .container h3, .container h5 {
      margin: 15px 0;
    }
  }
  
  /* For very small devices */
  @media (max-width: 480px) {
    main {
        width: 100%; /* 너비를 100%로 설정하여 작은 화면에서도 맞춤 */
        overflow-x: hidden; /* 가로 스크롤을 허용 (필요할 경우) */
      }
      .container {
        min-width: 100vw; /* 모바일에서도 컨테이너의 최소 너비를 뷰포트 너비로 설정 */
        font-size: 3rem; /* 작은 화면에서 폰트 크기 조정 (선택 사항) */
      }
  
    #title-container {
      height: 60vh;
    }
  
    #title-container .text {
      font-size: 1.5rem;
    }
  
    #title-container span {
      font-size: 1rem;
    }
  
    .container h3{
        width:70%;
        font-size: 20px;
        margin: 30px 0;
      } 

    .container h5 {
      width:70%;
      font-size: 10px;
      margin: 30px 0;
    } 
  }
  