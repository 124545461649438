@charset "utf-8";

/* 00. font - 구글폰트에서 Montserrat 300/400/600 가져옴 */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600&family=Noto+Sans+KR&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');/* regular-400, medium-500, semibold-600, bold-700 */ 
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

/* 00. Reset */
* {margin: 0;padding: 0;}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, tbody, tfoot, thead, tr, th, td{margin:0;padding:0;}
a {color: inherit; text-decoration: inherit;}
li {list-style: none;}
address, em, i {font-style: normal;}
button {background: none; border: none;}
img {vertical-align: middle;}

body {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    letter-spacing: -0.01em;
    background-color: #fff; /* 기본 배경색을 흰색으로 설정 */
    overflow-x: hidden;
}

.wrap {
    position: relative;
    margin: 0 auto;
    background: #fff; /* 기본 배경색을 흰색으로 설정 */
    color: #000; /* 텍스트 색상을 검정색으로 설정 */
}

/* 기본 스타일 */
header {
  position: fixed; /* 헤더를 화면 상단에 고정 */
  width: 100%;
  z-index: 9999;
  color: #fff;
  transition: top 0.3s; /* 숨기고 보이는 애니메이션 */
  top: 0;
}

header.hidden {
  top: -100px; /* 헤더의 높이만큼 위로 숨김 */
}

header .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
}

header .inner .logo {
  width: 120px; /* 로고 크기 조정 */
  display: flex;
}

header .inner .logo span {
  padding: 0 1px;
}

header .inner .logo span img {
  width: 100%;
}

header .gnb {
  text-align: right;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

header .gnb .menuBox {
  display: flex;
  list-style: none;
  gap: 15px; /* 메뉴 간격 조정 */
  margin: 0;
  padding: 0;
}

header .gnb .menuBox li {
  overflow: hidden;
}

header .gnb .menuBox li a {
  display: block;
  font-size: 14px; /* 폰트 사이즈 조정 */
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s;
}

header .gnb .menuBox li a:hover {
  color: #007bff;
}

body,html {height:100%;margin:0;padding:0}
ul, li, dl, dt, dd, p, span{margin:0;padding:0}
a{text-decoration:none}
li{list-style:none}
a{text-decoration:none}
*, :after, :before{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}

:root{--primary:#00a9ba;--k-font:'Pretendard';--e-font:"Kode Mono"}

.wrap.on {overflow: hidden;}

/* loading */
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: black;
  z-index: 9999;
  display: none;
  overflow: hidden; /* 숨겨진 부분이 있을 때 스크롤바를 방지 */
}

.loading .logo {
  width: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column; /* 수직 정렬 */
  justify-content: center;
  align-items: center; /* 중앙 정렬 */
}

.loading .logo span {
  display: block;
  margin-bottom: 40px; /* 아래 이미지와의 간격 조정 */
}

.loading .logo span img {
  width: 100%;
  height: auto; /* 비율 유지 */
  max-width: 150px; /* 이미지 최대 너비 조정 */
}

.loading .logo1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: calc(50% + 100px); /* 로고 아래에 위치 */
  transform: translate(-50%, -50%);
}

.loading .logo1 span {
  color: white;
  font-size: 24px; /* 폰트 크기 조정 */
  text-align: center; /* 텍스트 중앙 정렬 */
}

.loading.scene1 {
  display: block;
}

.loading.scene1 .logo span {
  animation-name: up;
  animation-duration: 1s;
}

/* Media Queries for Responsive Design */

/* 반응형 스타일 */
@media (max-width: 1200px) {
  .loading .logo {
    width: 180px;
  }

  .loading .logo span img {
    max-width: 130px;
  }

  .loading .logo1 span {
    font-size: 22px;
  }
}

@media (max-width: 992px) {
  .loading .logo {
    width: 160px;
  }

  .loading .logo span img {
    max-width: 110px;
  }

  .loading .logo1 span {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .loading .logo {
    width: 140px;
  }

  .loading .logo span img {
    max-width: 90px;
  }

  .loading .logo1 span {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .loading .logo {
    width: 120px;
  }

  .loading .logo span img {
    max-width: 70px;
  }

  .loading .logo1 span {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  header {
    width: 100%;
  }
  .loading .logo {
    width: 100px;
  }

  .loading .logo span img {
    max-width: 60px;
  }

  .loading .logo1 span {
    font-size: 14px;
  }
}
/* Animation for up effect */
@keyframes up {
  0% {
    transform: translate(-50%, -50%) translateY(0);
  }
  100% {
    transform: translate(-50%, -50%) translateY(-20px);
  }
}

@keyframes up {
  0% {
    transform: translateY(50px);
  }
  30% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
}

.opacity.on {
  animation-name: fadeIn;
  animation-duration: 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.footer {
  background-color: #000;
  color: #fff;
  padding: 10px 0; /* 위아래 패딩을 줄여서 높이 감소 */
  text-align: center;
  position: absolute; /* 페이지 하단에 고정 */
  bottom: 0;
  width: 100%;
  z-index: 100; /* 다른 요소 위에 표시 */
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.3); /* 약간의 그림자 추가 */
}

.footer-text {
  margin: 0;
  font-size: 0.9rem; /* 폰트 크기 조정 */
  color: white; /* 텍스트 색상 */
  z-index: 100; /* 다른 요소 위에 표시 */
}

/* 반응형 디자인을 위한 미디어 쿼리 */
@media (max-width: 768px) {
  .footer-text {
    font-size: 0.8rem; /* 작은 화면에서 폰트 크기 조정 */
  }
}


.Typewriter {
  display: inline; /* 인라인으로 설정 */
}

.Typewriter__cursor {
  display: inline; /* 인라인으로 설정 */
  margin-left: 2px; /* 텍스트와 커서 간의 간격 조정 */
  animation: blink 0.7s infinite; /* 깜빡이는 애니메이션 */
  color: white; /* 커서 색상 */
  width: 10px; /* 커서 너비 */
  height: 20px; /* 커서 높이 */
}

@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

/* 기본 스타일 */
body {
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  letter-spacing: -0.01em;
  background-color: #fff; /* 기본 배경색을 흰색으로 설정 */
}

.wrap {
  position: relative;
  margin: 0 auto;
  background: #fff; /* 기본 배경색을 흰색으로 설정 */
  color: #000; /* 텍스트 색상을 검정색으로 설정 */
}

/* 공통 요소 스타일 */
.inner {padding: 0 50px; box-sizing: border-box;}
.box {border-radius: 10px; overflow: hidden;}
.btn {font-size: 11px; padding: 12px 25px; box-sizing: border-box; border-radius: 30px; display: inline-block; background: #fff; color: #000; text-transform: uppercase; margin-top: 30px;}
#Contact {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; /* 반응형 레이아웃을 위한 flex-wrap 추가 */
  padding: 40px;
  width: 100%;
  height: 100vh; /* 높이 자동 조정 */
  background: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.7)), url(https://images.unsplash.com/photo-1542601906990-b4d3fb778b09?q=80&w=1313&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.contact-us {
  width: 45%; /* 좌측 영역 너비 */
  border-radius: 5%;
  background-color: #000;
  padding: 15px; /* 내부 여백 줄이기 */
  height: 70vh; /* 높이 조정 */
  box-sizing: border-box; /* 박스 사이징 설정 */
}

.contact-us h1, .contact-us h2 {
  color: #fff;
  margin: 8px 0; /* 여백 줄이기 */
  padding: 15px; /* 내부 여백 줄이기 */
}

.contact-us p {
  color: #fff;
  margin: 15px 0 8px; /* 여백 조정 */
  font-size: 14px; /* 폰트 크기 조정 */
  line-height: 1.4; /* 줄 높이 조정 */
}

.map-container {
  width: 50%; /* 우측 영역 너비 */
  height: 250px; /* 지도 높이 조정 */
  margin-top: 30px; /* 상단 여백 줄이기 */
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

/* 미디어 쿼리: 태블릿과 작은 화면에서 레이아웃 조정 */
@media (max-width: 768px) {
  #Contact {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .contact-us, .map-container {
    width: 100%; /* 너비를 100%로 설정하여 전체 화면 사용 */
    margin-top: 20px; /* 상단 여백 추가 */
  }

  .contact-us {
    padding: 10px; /* 내부 여백 줄이기 */
    height: 60vh; /* 높이 조정 */
  }

  .map-container {
    height: 200px; /* 지도 높이 조정 */
  }

  .contact-us h1, .contact-us h2 {
    margin: 6px 0; /* 여백 줄이기 */
    padding: 10px; /* 내부 여백 줄이기 */
  }

  .contact-us p {
    font-size: 12px; /* 폰트 크기 조정 */
    margin: 12px 0 6px; /* 여백 조정 */
  }
}

/* 미디어 쿼리: 매우 작은 화면에서 텍스트 크기와 레이아웃 조정 */
@media (max-width: 480px) {
  .contact-us {
    padding: 5px; /* 내부 여백 줄이기 */
    height: 50vh; /* 높이 조정 */
  }

  .map-container {
    height: 180px; /* 지도 높이 더 조정 */
  }

  .contact-us h1, .contact-us h2 {
    margin: 4px 0; /* 여백 줄이기 */
    padding: 8px; /* 내부 여백 줄이기 */
  }

  .contact-us p {
    font-size: 10px; /* 폰트 크기 조정 */
    margin: 8px 0 4px; /* 여백 조정 */
  }
}
