#con1 {
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.7)), url(https://cdn.pixabay.com/photo/2023/08/19/13/12/fern-8200455_1280.jpg);
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 0 1rem; /* Add some padding for small screens */
  }
  
  #con1 h1 {
    font-size: 3rem; /* Adjust font size for smaller screens */
  }
  
  #con1 .arrow {
    font-size: 4rem; /* Adjust font size for smaller screens */
  }
  
  #con1 .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center align items for small screens */
    height: 100%;
  }
  
  #con1 span {
    position: absolute;
    font-size: 3rem; /* Adjust font size for smaller screens */
    width: 100%;
    height: 100%;
    background-color: #4246ce;
    color: #0D0D0D;
    clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
    transform-origin: center;
    transition: all cubic-bezier(.1, .5, .5, 1) 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  #con1 .text:hover > span {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  
  #con1 .a {
    text-decoration: none;
    color: inherit;
  }
  
  /* Media Queries for Responsive Design */
  
  @media (max-width: 768px) {
    #con1 h1 {
      font-size: 2.5rem;
    }
  
    #con1 .arrow {
      font-size: 3rem;
    }
  
    #con1 .text {
      font-size: 1.5rem;
    }
  
    #con1 span {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 480px) {
    #con1 h1 {
      font-size: 1.8rem;
    }
  
    #con1 .arrow {
      font-size: 2rem;
    }
  
    #con1 .text {
      font-size: 1.2rem;
    }
  
    #con1 span {
      font-size: 1.5rem;
    }
  }
  