#con5 .list-item1{
    background: url('../images/KakaoTalk_20240612_035411822.webp');
    background-size: cover;
    background-position: center;
  }
  
  #con5 .list-item2{
    background: url('../images/anni_forge.webp');
    background-size: cover;
    background-position: center;
  }
  /* 기본 스타일 */
#con5 {
    width: 100%;
    height: 100vh;
    margin-top: 0;
    padding-top: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.7)), url(https://images.unsplash.com/photo-1514064019862-23e2a332a6a6?q=80&w=1314&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
  
  #con5 .list {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    gap: 10vw;
  }
  
  #con5 .list-item1, #con5 .list-item2 {
    width: 30vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    border-radius: 4%;
    padding: 4vw;
    box-sizing: border-box;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
    transition: transform 0.6s cubic-bezier(.1, .5, .5, 1);
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  
  #con5 .list-item1:hover, #con5 .list-item2:hover {
    transform: rotateY(180deg) !important;
  }
  
  #con5 .list-item1 h1, #con5 .list-item2 h1, #con5 .list-item1 h2, #con5 .list-item2 h2, #con5 .list-item1 h4, #con5 .list-item2 h4 {
    backface-visibility: hidden !important;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  #con5 .list-item1 h1, #con5 .list-item2 h1 {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 2.5em;
  }
  
  #con5 .list-item1 h2, #con5 .list-item2 h2 {
    margin-bottom: 15px;
    font-size: 2em;
  }
  
  #con5 .list-item1 h4, #con5 .list-item2 h4 {
    line-height: 1.5;
    font-size: 1.5em;
    opacity: 0.5;
  }
  
  #con5 .list-item1 span, #con5 .list-item2 span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    color: #0D0D0D;
    border-radius: 4%;
    transform: rotateY(180deg);
    backface-visibility: hidden;
  }
  
  #con5 .list-item1:hover span, #con5 .list-item2:hover span {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  
  /* 반응형 디자인을 위한 미디어 쿼리 */
  @media (max-width: 1024px) {
    #con5 .list-item1, #con5 .list-item2 {
      width: 45vw;
      height: 80vh;
    }
  
    #con5 .list {
      gap: 5vw;
    }
  }
  
  @media (max-width: 768px) {
    #con5 {
        width: 100%;
        height: 200vh;
      }
      #con5 .list-item1, #con5 .list-item2 {
        width: 80vw;
        height: 80vh;
      }
      #con5 .list {
        gap: 5vw;
      }
  }
  
  @media (max-width: 480px) {
    #con5 {
        width: 100%;
        height: 120vh;
      }
      
      #con5 .list-item1, #con5 .list-item2 {
        width: 80vw;
        height: 50vh;
      }
    
      #con5 .list {
        flex-direction: column;
      }
      #con5 .list-item1 h1, #con5 .list-item2 h1 {
        font-weight: 700;
        margin-bottom: 10px;
        font-size: 1.5em;
      }
      
      #con5 .list-item1 h2, #con5 .list-item2 h2 {
        margin-bottom: 15px;
        font-size: 1em;
      }
      
      #con5 .list-item1 h4, #con5 .list-item2 h4 {
        line-height: 1.5;
        font-size: 0.5em;
        opacity: 0.5;
      }
    
  }
  