/* .con3 스타일 */
/* 기본 스타일 */
.con3 {
    position: relative;
    display: flex;
    height: 500vh;
    font-family: 'Pretendard';
  }
  
  .con3 .circle {
    display: block;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    color: white;
    background: black;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    opacity: 0;
    text-align: center;
  }

  .con3 .circle .text {
    margin-top: 15%;
    white-space: pre-line; /* 줄바꿈 문자 인식 */
    line-height: 1.5;
  }
  
  .con3 * {
    word-break: keep-all;
  }
  
  .con3 .l_cont, .con3 .r_cont {
    width: 50%;
  }
  
  .con3 .item {
    height: 100vh;
  }
  
  .con3 .txt_wrap {
    display: flex;
    flex-direction: column;
    gap: 100px;
    height: 100%;
    width: 100%;
    padding: 120px;
    font-size: 15px;
    color: black;
  }
  
  .con3 .txt_wrap dt {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: var(--e-font);
  }
  
  .con3 .txt_wrap dd {
    font-size: 20px;
  }
  
  .con3 .txt_wrap dd p {
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 700;
  }
  
  .con3 .l_cont .cont01,
  .con3 .l_cont .cont02,
  .con3 .l_cont .cont03,
  .con3 .l_cont .cont04 {
    background: white;
  }
  
  .con3 .r_cont .cont01 {
    background: url(https://images.unsplash.com/photo-1505017791108-7b40f307cdc5?q=80&w=1364&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
    background-size: cover;
    background-position: center;
  }
  
  .con3 .r_cont .cont02 {
    background: url(https://millertimber.com/wp-content/uploads/2021/12/0A9A1239.jpg);
    background-size: cover;
    background-position: center;
  }
  
  .con3 .r_cont .cont03 {
    background: url(https://cdn1.environmentamericas.org/wp-content/uploads/2022/06/06132044/seedballssprouting-570x570.jpg);
    background-size: cover;
    background-position: center;
  }
  
  .con3 .r_cont .cont04 {
    background: url(https://images.pexels.com/photos/2280568/pexels-photo-2280568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    background-size: cover;
    background-position: center;
  }
  
  .con3 .txt_wrap .btn {
    display: inline-block;
    background-color: black;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-size: 16px;
    text-align: center;
  }
  
  .con3 .txt_wrap .btn:hover {
    background-color: #333;
  }
  
  /* Media Queries for Responsive Design */
  
  /* For tablets and smaller screens */
  @media (max-width: 1024px) {
    .con3 .circle {
      width: 150px;
      height: 150px;
    }
  
    .con3 .txt_wrap {
      padding: 80px;
      gap: 50px;
      font-size: 14px;
    }
  
    .con3 .txt_wrap dt {
      font-size: 24px;
    }
  
    .con3 .txt_wrap dd p {
      font-size: 20px;
    }
  
    .con3 .txt_wrap .btn {
      font-size: 14px;
    }
  }
  
  /* For mobile devices */
  @media (max-width: 768px) {
    .con3 .circle {
      width: 100px;
      height: 100px;
    }
      .con3 .txt_wrap {
        font-size: 7px;

      }
    
      .con3 .txt_wrap dt {
        font-size: 12px;
      }
    
      .con3 .txt_wrap dd p {
        font-size: 10px;
      }
      .con3 .txt_wrap dd .pl {
        font-size: 7px;
      }
      .con3 .txt_wrap .btn {
        font-size: 7px;
      }
      .con3 .circle .text {
        margin-top: 20%;
        white-space: pre-line; /* 줄바꿈 문자 인식 */
        line-height: 1.5;
        font-size: 12px;
      }
  }
  
  /* For very small devices */
@media (max-width: 480px) {
  .con3 .txt_wrap {
    padding-top: 100%;
    font-size: 7px;
    width: 100%;
    padding: 50px;
    gap: 50px;
  }

  .con3 .txt_wrap dt {
    font-size: 10px;
  }

  .con3 .txt_wrap dd p {
    font-size: 7px;
  }

  .con3 .txt_wrap dd .pl {
    font-size: 5px;
  }

  .con3 .txt_wrap .btn {
    font-size: 5px;
  }

  .con3 .circle {
    width: 80px; /* 기존 스타일 유지 */
    height: 80px; /* 기존 스타일 유지 */
  }

  .con3 .circle .text {
    margin-top: 30%;
    white-space: pre-line; /* 줄바꿈 문자 인식 */
    line-height: 1.5;
    font-size: 7px;
  }
}

/* Galaxy S21 Ultra, Note 20 Ultra */
@media (min-width: 412px) and (max-width: 440px) {
  .con3 .circle {
    top: 40%;
    left: 45%;
    width: 80px; /* 기존 스타일 유지 */
    height: 80px; /* 기존 스타일 유지 */
    transform: translate(-50%, -50%); /* 원의 정확한 중앙 정렬 */
  }
}

