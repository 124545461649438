/* #leader Section */
#leader {
    width: 100%;
    height: 50vh;
    background-color: #000;
  }
  
  #leader .leader-content {
    color: #000;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  
  #leader h1,
  #leader .arrow {
    color: white;
  }
  
  #leader h1 {
    font-size: 4rem;
  }
  
  #leader .arrow {
    font-size: 2rem;
  }
  
  /* .con2 Section */
  .con2 {
    background: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.7)), 
                url(https://images.unsplash.com/photo-1514064019862-23e2a332a6a6?q=80&w=1314&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
  
  #container4 {
    width: 100%;
    height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }
  
  .con2 .listBox {
    perspective: 900px;
  }
  
  .con2 .listBox .box {
    background: #fff;
    justify-content: center;
    padding: 20px 30px;
    margin: 0 auto 35px; /* Center alignment */
    color: #141212;
    display: grid;
    grid-template-columns: 1fr auto;
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    filter: brightness(1);
    max-width: 800px;
  }
  
  .con2 .listBox .box h2 {
    font-size: 50px;
    font-weight: normal;
    letter-spacing: -1px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    grid-column: 1/3;
    margin-bottom: 30px;
  }
  
  .con2 .listBox .box h3 {
    font-size: 90px;
    font-weight: bold;
    align-self: flex-end;
    padding-bottom: 7.5vh;
  }
  
  .con2 .listBox .box .text1 {
    align-self: flex-end;
    line-height: 1.4;
  }
  
  .con2 .listBox .box h4 {
    font-size: 20px;
    padding-bottom: 10vh;
    opacity: 0.5;
    text-align: right;
  }
  
  .con2 .listBox .box.a h3 { color: #ffb84f; }
  .con2 .listBox .box.b h3 { color: #90c3cd; }
  .con2 .listBox .box.c h3 { color: #47b172; }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    #leader h1 {
      font-size: 3rem; /* Adjust font size for tablets */
    }
  
    #leader .arrow {
      font-size: 1.5rem; /* Adjust font size for tablets */
    }
  
    .con2 .listBox .box {
      padding: 15px 20px; /* Adjust padding for tablets */
      max-width: 90%; /* Make box width responsive */
    }
  
    .con2 .listBox .box h2 {
      font-size: 40px; /* Adjust font size for tablets */
    }
  
    .con2 .listBox .box h3 {
      font-size: 70px; /* Adjust font size for tablets */
    }
  
    .con2 .listBox .box h4 {
      font-size: 18px; /* Adjust font size for tablets */
    }
  }
  
  @media (max-width: 768px) {
    #leader h1 {
      font-size: 2.5rem; /* Adjust font size for mobile devices */
    }
  
    #leader .arrow {
      font-size: 1.2rem; /* Adjust font size for mobile devices */
    }
  
    .con2 .listBox .box {
      padding: 10px 15px; /* Adjust padding for mobile devices */
      max-width: 100%; /* Full width for mobile devices */
    }
  
    .con2 .listBox .box h2 {
      font-size: 30px; /* Adjust font size for mobile devices */
    }
  
    .con2 .listBox .box h3 {
      font-size: 50px; /* Adjust font size for mobile devices */
    }
  
    .con2 .listBox .box h4 {
      font-size: 16px; /* Adjust font size for mobile devices */
    }
  }
  
  @media (max-width: 480px) {
    #leader h1 {
      font-size: 2rem; /* Adjust font size for very small devices */
    }
  
    #leader .arrow {
      font-size: 1rem; /* Adjust font size for very small devices */
    }
    .con2 .listBox .box {
        padding: 10px 20px 20px;
        max-height: 30vh; /* 화면의 최대 높이 조정 */
    }
  
    .con2 .listBox .box h2 {
      font-size: 25px; /* Adjust font size for very small devices */
      margin-bottom: 0px;
    }
  
    .con2 .listBox .box h3 {
      font-size: 40px; /* Adjust font size for very small devices */
    }
  
    .con2 .listBox .box h4 {
      padding-bottom: 0;
      font-size: 14px; /* Adjust font size for very small devices */
      text-align: center;
    }
  }
  